import React, { ReactNode } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { Row, Col, Nav } from 'react-bootstrap';

import { routes as routesDef } from '../../App';
import { DashboardTitle } from './Dashboard';

type RouteDef = any;

type NavLinkProps = {
  children: any;
  to: string;
}
const NavLink = ({ to, children }: NavLinkProps) => (
  <Nav.Item>
    <Link to={to} className='nav-item'>
      <div className='nav-link'>{children}</div>
    </Link>
  </Nav.Item>
);

type NavLinksProps = {
  routes?: RouteDef[];
  path?: string;
  breadcrumb?: ReactNode;
}


export const NavLinks = ({
  routes = routesDef,
  path: pathRoot='',
  breadcrumb,
}: NavLinksProps) => {
  const { pathname } = useLocation();
  return (
    <>
      {
        routes
          .filter(({ index }: RouteDef) => !index)
          .map(({ path, title, children, index }: RouteDef, key: number) => {
            const newPath = `${pathRoot}/${path}`;
            const newBreadcrumb = (
              <>
                {breadcrumb}
                {breadcrumb && ' / '}
                <Link to={newPath}>
                  {title}
                </Link>
              </>
            );
            return (
              <div key={key} >
                <NavLink to={newPath}>
                  {title}
                </NavLink>
                {children && pathname.startsWith(newPath) &&
                  <div style={{ marginLeft: '20px' }}>
                    <NavLinks
                      routes={children}
                      path={newPath}
                      breadcrumb={newBreadcrumb}
                    />
                  </div>
                }
                {pathname === newPath && <DashboardTitle>{newBreadcrumb}</DashboardTitle>}
              </div>
            );
        })
      }
    </>
  )
};

const SideBar = () => {
  const [currentPath, currentSubPath] = useLocation().pathname.substr(1).split('/')

  return <>
    <Row style={{ width: '100%'}}>
      <Col sm="2" className="sidebar">
        <Nav className="d-none d-md-block bg-light fluid"  style={{ minHeight: 'calc(100vh - 58px)'}}>
          <NavLinks />
        </Nav>
      </Col>
      <Col sm="10">
        <Outlet />
      </Col>
    </Row>
  </>
};
export default SideBar;
