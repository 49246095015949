
import axios from '../utils/Axios';
import { toastOnError } from '../utils/Utils';
import reduxCrudFactory from '../utils/ReduxCrudFactory';

const factory = reduxCrudFactory({
  axios,
  onError: toastOnError,
  actions: {
    get: true,
    getList: true,
    create: true,
    update: true,
    delete: true,
  },
  config: {
    users: {
      route: '/api/admin/users/',
    },
    roles: {
      route: '/api/admin/roles/',
      actions: {
        delete: { callback: ({ getUsersList }) => getUsersList() },
      }
    },
    locations: {
      route: '/api/admin/locations/',
      actions: {
        delete: { callback: ({ getUsersList }) => getUsersList() },
      }
    },
    productionTemplates: {
      route: '/api/admin/production-templates/',
      includeActions: {
        ...Object.entries({
          createTask: 'post',
          updateTask: 'put',
          deleteTask: 'delete',
        }).reduce((o, [action, method]) => ({
          ...o,
          [action]: {
            route: ({}, { args }) => `/api/admin/production-templates/${args.productionTemplate.id}/task/`,
            method,
            onResponse: (productionTemplate, { setProductionTemplate }) => setProductionTemplate(productionTemplate),
          },
        }), {}),
        moveTask: {
          isAsync: true,
          route: ({}, { args }) => `/api/admin/production-templates/${args.productionTemplate.id}/task/move/`,
          method: 'put',
          prepare: ({ source, target, position }) => { console.log(source.order, target.order, position); return ({ source, target, position })},
          onResponse: (productionTemplate, { setProductionTemplate }) => setProductionTemplate(productionTemplate),
        },
      },
    },
    taskTemplates: {
      route: '/api/admin/task-templates/',
      includeActions: {
        moveTaskTemplate: {
          isAsync: true,
          route: ({ taskTemplate }) =>  `/api/admin/task-templates/${taskTemplate.id}/move/`,
          method: 'put',
          prepare: ({ target, position }) => ({ target, position }),
          onResponse: (objs, { setTaskTemplatesList }) => setTaskTemplatesList(objs),
        },
      },
    },
    projects: {
      route: '/api/admin/projects/',
      parseIdToInt: true,
      includeActions: {
        ...Object.entries({
          startTask: 'start-task',
          finishTask: 'finish-task',
          updateTask: 'update-task',
          resetTask: 'reset-task',
        }).reduce((o, [action, route]) => ({
          ...o,
          [action]: {
            route: ({}, { args }) => `/api/admin/projects/${args.project.id}/${route}/`,
            method: 'post',
            onResponse: (project, { setProject }) => setProject(project),
          },
        }), {}),

        ...Object.entries({
          hideProject: 'hide',
          showProject: 'show',
        }).reduce((o, [action, route]) => ({
          ...o,
          [action]: {
            route: project => `/api/admin/projects/${project.id}/${route}/`,
            method: 'post',
            onResponse: (project, { setProject }) => setProject(project),
          },
        }), {}),

        moveProject: {
          isAsync: true,
          route: ({ project }) =>  `/api/admin/projects/${project.id}/move/`,
          method: 'put',
          prepare: ({ target, position }) => ({ target, position }),
          // The response is handled in the component
        },
      },
    },
    teamleaderDeals: {
      route: '/api/admin/teamleader/deals/',
      actions: { create: false, delete: false },
      includeActions: {
        connectTeamleader: {
          route: `/api/admin/teamleader/authorize/`,
          onResponse: ({ url }) => window.location = url,
        },
        authorizeTeamleader: {
          route: `/api/admin/teamleader/authorize/`,
          method: 'post'
          // Handle response in TeamleaderAuthorize component as it able to redirect without reloading
        },
        hideTeamleaderDeal: {
          route: teamleader_deal => `/api/admin/teamleader/deals/${teamleader_deal.id}/hide/`,
          method: 'post',
          onResponse: (td, { setTeamleaderDeal }) => setTeamleaderDeal(td),
        },
        showTeamleaderDeal: {
          route: teamleader_deal => `/api/admin/teamleader/deals/${teamleader_deal.id}/show/`,
          method: 'post',
          onResponse: (td, { setTeamleaderDeal }) => setTeamleaderDeal(td),
        },
        synchronizeTeamleaderDeals: {
          route: '/api/admin/teamleader/deals/synchronize/',
          onResponse: (objs, { setTeamleaderDealsList }) => setTeamleaderDealsList(objs),
        }
      },
    },
    teamleaderDealPhases: {
      route: '/api/admin/teamleader/deal-phases/',
      actions: { create: false, delete: false },
    },
    teamleaderCustomFieldDefinitions: {
      route: '/api/admin/teamleader/custom-field-definitions/',
      actions: { create: false, delete: false, update: false },
    },
    settings: {
      id: 'key',
      route: '/api/admin/settings/',
      actions: { create: false, delete: false },
    },
  },
});

export default factory;

export const actions = factory.actions;
export const mapToProps = factory.mapToProps;
export const use = factory.hooks;
