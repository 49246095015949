import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Badge, Tabs, Tab } from 'react-bootstrap';
import moment from 'moment';

import { use } from '../../redux/factory';
import {
  SmallSpinner,
  EditButton,
  CogButton,
  CreateButton,
  HideButton,
  ShowButton,
  CreateEditModal as EditModal,
  DeleteConfirmButton,
  DataTable,
} from '../shared/ReactToolbox';
import { updateFormFields } from './ProjectView';
import TaskTemplate from '../production/TaskTemplateBadge';
import TeamleaderCustomFieldDefinitionsFormField from '../teamleader/TeamleaderCustomFieldDefinitionsFormField';
import TeamleaderClient from '../teamleader/TeamleaderClient';
import CreateProjectModal from './CreateProjectModal';
import ProjectsDataTable from './ProjectsDataTable';

const ProjectsList = () => {
  const {
    projectsList,
    getProjectsList,
    hideProject,
    showProject,
  } = use.projects();

  const navigate = useNavigate();
  const { updateSetting, settingsList } = use.settings();

  const { getTeamleaderDealsList, getTeamleaderDeal, teamleaderDealsList } = use.teamleaderDeals();
  const { teamleaderCustomFieldDefinitionsList } = use.teamleaderCustomFieldDefinitions();
  const { teamleaderDealPhasesList } = use.teamleaderDealPhases();
  const { productionTemplatesList } = use.productionTemplates();
  const { taskTemplatesList } = use.taskTemplates();
  useEffect(() => {
    getProjectsList();
    getTeamleaderDealsList();
  }, []);

  const [customFieldDefinitionsModalActive, setCustomFieldDefinitionsModalActive] = useState(false);
  const [activeTab, setActiveTab] = useState('projects');
  const [teamleaderDealInProjectModal, setTeamleaderDealInProjectModal] = useState(null)

  if (
    !projectsList
    || !teamleaderDealsList
    || !settingsList
    || !teamleaderCustomFieldDefinitionsList
    || !teamleaderDealPhasesList
    || !productionTemplatesList
    || !taskTemplatesList
  ) return <SmallSpinner className='vertical-space' />;
  const cfd = settingsList.teamleader_custom_field_definitions_in_projects_table;

  return <>
    <Container className="container-list container-table">
      <Tabs
        activeKey={activeTab}
        onSelect={tab => {
          setActiveTab(tab);
        }}
        className="mb-3"
      >
        {Object.entries({
          projects: "Projecten",
          finished: "Afgemaakt",
        }).map(([eventKey, title], key) => (
          <Tab {...{ eventKey, title, key }} />
        ))}
      </Tabs>

      {projectsList &&
        <ProjectsDataTable
          projects={
            Object.values(projectsList)
              .filter(({ teamleader_deal: td, hidden }) =>
                td && teamleaderDealPhasesList[td.phase] && (
                  activeTab === 'projects'
                  ? !teamleaderDealPhasesList[td.phase].hide_deals && !hidden
                  : teamleaderDealPhasesList[td.phase].hide_deals || hidden
                )
              )
          }
          showHideButton={activeTab === 'projects'}
          additionalActions={({ project }) =>
            <>
              <CreateButton
                className='float-end'
                onClick={() => setTeamleaderDealInProjectModal(project.teamleader_deal)}
                style={{ marginLeft: '4px' }}
                title='Nieuw project toevoegen aan offerte van dit project'
              />
            </>
          }
        />
      }

      <CogButton onClick={() => setCustomFieldDefinitionsModalActive(true)}>
        Kolommen beheren
      </CogButton>


      {customFieldDefinitionsModalActive &&
        <EditModal
          onHide={() => setCustomFieldDefinitionsModalActive(false)}
          modalTitle="Kolommen toevoegen"
          loading={updateSetting.isLoading}
          formFields={{
            value: {
              label: 'Offertevelden',
              component: TeamleaderCustomFieldDefinitionsFormField,
            }
          }}
          initialState={cfd}
          onSave={setting => updateSetting(
            setting,
            { callback: () => setCustomFieldDefinitionsModalActive(false) }
          )}
        />
      }

      {teamleaderDealInProjectModal &&
        <CreateProjectModal
          onHide={() => setTeamleaderDealInProjectModal(null)}
          onCreate={async ({ id }) => {
            await getTeamleaderDeal(teamleaderDealInProjectModal);
            setTeamleaderDealInProjectModal(null)
            navigate(`/projects/${id}`);
          }}
          teamleaderDeal={teamleaderDealInProjectModal}
        />
      }
    </Container>
  </>;
};
export default ProjectsList;
