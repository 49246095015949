import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { use } from '../../redux/factory';

const TeamleaderAuthorize = () => {
  const { authorizeTeamleader } = use.teamleaderDeals();
  const navigate = useNavigate();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    authorizeTeamleader(
      { code: urlParams.get('code') },
      { callback: () => navigate('/') }
    );
  }, [])
  return null;
}
export default TeamleaderAuthorize;
