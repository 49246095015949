import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import _ from 'lodash';

import { logout } from '../../redux/actions/Login';
import Sidebar, { NavLinks } from './Sidebar';
import { use } from '../../redux/factory';
import { MenuButton } from '../shared/ReactToolbox';

const Dashboard = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.auth.user);
  const { getUsersList } = use.users();
  const { getLocationsList } = use.locations();
  const { getTaskTemplatesList } = use.taskTemplates();
  const { getRolesList } = use.roles();
  const { getProductionTemplatesList } = use.productionTemplates();
  const { getTeamleaderCustomFieldDefinitionsList } = use.teamleaderCustomFieldDefinitions();
  const { getSettingsList } = use.settings();
  const { getTeamleaderDealPhasesList } = use.teamleaderDealPhases();
  useEffect(() => {
    getUsersList();
    getRolesList();
    getLocationsList();
    getTaskTemplatesList();
    getProductionTemplatesList();
    getTeamleaderCustomFieldDefinitionsList();
    getSettingsList();
    getTeamleaderDealPhasesList();
  }, []);
  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      <Navbar bg='light'>
        <Link to="/">
          <Navbar.Brand>Dashboard</Navbar.Brand>
        </Link>
        <MenuButton
          className="p-0 d-md-none ms-3"
          size='sm'
          onClick={() => setShowMenu(!showMenu)}
        />
        <Nav className="mr-auto">
          <Navbar.Text id="dashboard-title">
            <div></div>
          </Navbar.Text>
        </Nav>
        <Navbar.Collapse className='justify-content-end'>
          <Navbar.Text>
            <b>{user &&(user.name || user.username)}</b>&nbsp;
          </Navbar.Text>
          <Nav.Link onClick={() => dispatch(logout())}>Logout</Nav.Link>
        </Navbar.Collapse>
      </Navbar>
      {showMenu &&
        <div style={{ width: '100%'}} className="bg-light">
          <div className='p-0 d-md-none ms-3'>
            <div className="divider" />
            <Nav className="d-block">
              <NavLinks />
            </Nav>
          </div>
        </div>
      }
      <Sidebar />
    </>
  );
}
export default Dashboard;

// Or use context providers https://stackoverflow.com/questions/70655186/is-it-possible-to-use-multiple-outlets-in-a-component-in-react-router-v6
export const DashboardTitle = ({ children }: { children: any }) => {
  const element = document.getElementById('dashboard-title');
  return element ? createPortal(children, element) : null;
}
