import { CSSProperties } from 'react';
import { Badge, BadgeProps } from 'react-bootstrap';
import { TaskTemplate, ProjectTaskStatus } from '../../redux/types';

interface TaskTemplateBadgeProps extends BadgeProps {
  cursor?: CSSProperties['cursor'],
  taskTemplate: TaskTemplate,
  status: ProjectTaskStatus,
}
const TaskTemplateBadge = ({
  cursor,
  taskTemplate,
  status,
  ...props
}: TaskTemplateBadgeProps) => {
  return <Badge
    bg={
      status === 'unassigned'
      ? 'success'
      : status === 'started'
      ? 'info'
      : status === 'blocked'
      ? 'secondary'
      : 'primary'
    }
    style={{ ...cursor ? { cursor } : {} }}
    className='task-template'
    {...props}
  >
    {taskTemplate.name}
  </Badge>
}
export default TaskTemplateBadge;
