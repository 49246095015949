import { Badge } from 'react-bootstrap';

const Role = ({ cursor, hasRole = true, ...props }) => (
  <Badge
    bg={hasRole ? 'primary' : 'secondary'}
    style={{ ...cursor ? { cursor } : {} }}
    className='role'
    {...props}
  />
);
export default Role;
