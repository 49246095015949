import { Badge } from 'react-bootstrap';

import { use } from '../../redux/factory';

const LocationsFormField = ({ value: locationIds, onChange }) => {
  const { locationsList } = use.locations();
  return locationsList ? (
    <div className="form-control-lg">
      {Object.values(locationsList).map((location, key) => {
        const hasLocation = locationIds && locationIds.find(id => id === location.id);

        return (
          <Badge
            key={key}
            bg={hasLocation ? "primary" : "secondary"}
            style={{ cursor: 'pointer' }}
            className='location'
            onClick={() => {
              if (hasLocation) {
                onChange(locationIds.filter(id => id !== location.id));
              } else {
                onChange([...locationIds, location.id]);
              }
            }}
            >
          {location.name}
          </Badge>
        )
      })}
    </div>
  ) : null
}
export default LocationsFormField;
