import { FormSelect } from '../shared/ReactToolbox';
import { use } from '../../redux/factory';

const UsersFormField = (props) => {
  const { usersList } = use.users();
  return (
    <FormSelect
      {...props}
      list={usersList}
      integer
      formatTitle={({ name, username }) => name || username}
    />
  );
}
export default UsersFormField
