import { useState, useEffect } from 'react';
import { Container, Table } from 'react-bootstrap';

import { use } from '../../redux/factory';
import {
  SmallSpinner,
  CreateButton,
  DeleteConfirmButton,
  CreateEditModal as CreateModal,
  CreateEditModal as EditModal,
} from '../shared/ReactToolbox';

const LocationsList = () => {
  const {
    locationsList,
    getLocationsList,
    updateLocation,
    createLocation,
    deleteLocation,
  } = use.locations();
  const [createLocationModalActive, setCreateLocationModalActive] = useState(false);
  const [locationInEditModal, setLocationInEditModal] = useState(null);

  useEffect(() => {
    getLocationsList();
  }, []);
  if (!locationsList) return <div className='vertical-space'><SmallSpinner /></div>;
  const formFields = {
    name: {
      label: 'Locatie',
    },
  };
  return <>
    <Container className="locations-list">
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Locatie</th>
          </tr>
        </thead>
        <tbody>
          {locationsList && Object.values(locationsList).map((location, key) =>
            <tr
              key={key}
              onClick={() => setLocationInEditModal(location)}
            >
              <td>
                {location.name}
                <DeleteConfirmButton
                  className="float-end"
                  onDelete={() => deleteLocation(location)}
                />
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <CreateButton onClick={() => setCreateLocationModalActive(true)} />

      {createLocationModalActive &&
        <CreateModal
          onHide={() => setCreateLocationModalActive(false)}
          modalTitle="Locatie toevoegen"
          loading={createLocation.isLoading}
          initialState={Object.keys(formFields).reduce((o, key) => ({ ...o, key: '' }), {})}
          formFields={formFields}
          onSave={location => createLocation(
            location,
            { callback: () => setCreateLocationModalActive(false) }
          )}
        />
      }

      {locationInEditModal &&
        <EditModal
          onHide={() => setLocationInEditModal(null)}
          modalTitle="Locatie aanpassen"
          loading={updateLocation.isLoading}
          initialState={locationInEditModal}
          formFields={formFields}
          onSave={location => updateLocation(
            location,
            { callback: () => setLocationInEditModal(null) }
          )}
        />
      }
    </Container>
  </>;
};
export default LocationsList;
