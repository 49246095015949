import React, { Component, createRef } from 'react';
import Gantt from './Gantt/Gantt';

export class Task {
  constructor({ id, start = '', end = '', progress = 0, dependencies = [], ...options } = {}) {
    this.dependencies = dependencies.map(id => `${id}`);
    this.id = `${id}`;
    this.order = ''
    this.production_template = null;
    this.task_template = null;
    this.name = ''
    this.start = new Date(start);
    this.end = new Date(end);
    this.progress = progress;
    Object.assign(this, options);
  }
}

export default class FrappeGantt extends Component {
  constructor(props) {
    super(props);
    this._target = createRef();
    this._svg = createRef();
    this._gantt = null;

    this.state = {
      viewMode: null,
      tasks: [],
    };
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      viewMode: nextProps.viewMode,
      tasks: nextProps.tasks.map((t) => new Task(t)),
    };
  }

  componentDidUpdate() {
    if (this._gantt) {
      this._gantt.refresh(this.state.tasks);
      this._gantt.change_view_mode(this.state.viewMode);
    }
  }

  onTaskChange(changedTask) {
    if (typeof this.props.onTaskChange === 'function') {
      this.props.onTaskChange(
        changedTask,
        this.props.tasks.map(task => task.id === changedTask.id ? changedTask : task)
      );
    }
  }
  componentDidMount() {
    this._gantt = new Gantt(
      this._svg.current,
      this.state.tasks,
      {
        on_click: this.props.onClick,
        on_view_change: this.props.onViewChange,
        on_progress_change: (task, progress) => this.onTaskChange({ ...task, progress }),
        on_date_change: (task, start, end) => this.onTaskChange({ ...task, start, end }),
        custom_popup_html: this.props.customPopupHtml || null,
        custom_popup_html_callback: this.props.customPopupHtmlCallback || null,
        popup_trigger: this.props.popupTrigger || 'click',
        read_only: !!this.props.readOnly,
      }
    );

    if (this._gantt) {
      this._gantt.change_view_mode(this.state.viewMode);
      if (typeof this.props.setGantt === 'function') {
        this.props.setGantt(this._gantt);
      }
    }

    const midOfSvg = this._svg.current.clientWidth * 0.5;
    this._target.current.scrollLeft = midOfSvg;
  }

  render() {
    return (
      <div ref={this._target}>
        <svg
          ref={this._svg}
          width='100%'
          height='100%'
          xmlns='http://www.w3.org/2000/svg'
          xmlnsXlink='http://www.w3.org/1999/xlink'
        />
      </div>
    );
  }
}

export const ViewMode = {
  QuarterDay: 'Quarter Day',
  HalfDay: 'Half Day',
  Day: 'Day',
  Week: 'Week',
  Month: 'Month',
};
