import { Badge } from 'react-bootstrap';
import { MdCleaningServices } from 'react-icons/md';

import { use } from '../../redux/factory';
import { hasStarted } from './ProjectView';
import { Project, TaskTemplatesList } from '../../redux/types';

export type SelectTaskTemplatesProps = {
  projects: Project[];
  selectedTaskTemplates: number[];
  setSelectedTaskTemplates: (taskTemplates: number[]) => void;
}

const SelectTaskTemplates = ({
  projects,
  selectedTaskTemplates,
  setSelectedTaskTemplates,
}: SelectTaskTemplatesProps) => {

  const { taskTemplatesList } = use.taskTemplates() as { taskTemplatesList: TaskTemplatesList };
  if (projects.length === 0 || !taskTemplatesList) return null;

  const uniqueTaskTemplates: number[] = Array.from(
    projects.reduce((acc, project) => {
      project.tasks
        .filter(hasStarted)
        .forEach(task => acc.add(task.task_template));
      return acc;
    }, new Set<number>())
  );

  return (
    <>
      <Badge
        className='task-template'
        bg={selectedTaskTemplates.length > 0 ? "primary" : "secondary"}
        onClick={() => setSelectedTaskTemplates([])}
        style={selectedTaskTemplates.length > 0 ? { cursor: 'pointer' } : {}}

      >
        <MdCleaningServices />
      </Badge>
      {
        uniqueTaskTemplates
          .sort((id1: number, id2: number) =>
              taskTemplatesList[id1]?.name > taskTemplatesList[id2]?.name ? 1 : -1
            )
          .map((taskTemplateId: number) => {
            const isSelected = selectedTaskTemplates.includes(taskTemplateId);
            return (
              <Badge
                key={taskTemplateId}
                bg={isSelected ? "primary" : "secondary"}
                style={{ cursor: 'pointer' }}
                className='task-template'
                onClick={() => {
                  if (isSelected) {
                    setSelectedTaskTemplates(selectedTaskTemplates.filter(id => id !== taskTemplateId));
                  } else {
                    setSelectedTaskTemplates([...selectedTaskTemplates, taskTemplateId]);
                  }
                }}
              >
                {taskTemplatesList[taskTemplateId]
                  ? taskTemplatesList[taskTemplateId].name
                  : <i>Niet gevonden</i>
                }
              </Badge>
            );
          })
      }
    </>
  );
}

export default SelectTaskTemplates;
