import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Table } from 'react-bootstrap';

import { use } from '../../redux/factory';
import {
  SmallSpinner,
  CreateButton,
  EditButton,
  DeleteConfirmButton,
  CreateEditModal as CreateModal,
  CreateEditModal as EditModal,
} from '../shared/ReactToolbox';
import { formFields } from './ProductionTemplate';

const ProductionTemplatesList = () => {
  const {
    productionTemplatesList,
    getProductionTemplatesList,
    updateProductionTemplate,
    createProductionTemplate,
    deleteProductionTemplate,
  } = use.productionTemplates();
  const [createProductionTemplateModalActive, setCreateProductionTemplateModalActive] = useState(false);
  const [productionTemplateInEditModal, setProductionTemplateInEditModal] = useState(null);
  useEffect(() => {
    getProductionTemplatesList();
  }, []);

  if (!productionTemplatesList) return <SmallSpinner className='vertical-space' />;
  return <>
    <Container className="container-list">
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Naam</th>
            <th>Acties</th>
          </tr>
        </thead>
        <tbody>
          {productionTemplatesList && Object.values(productionTemplatesList).map((productionTemplate, key) =>
            <tr
              key={key}
            >
              <td>
                <Link to={`${productionTemplate.id}`}>
                  {productionTemplate.name}
                </Link>
              </td>
              <td>
                <DeleteConfirmButton
                  className="float-end"
                  onDelete={() => deleteProductionTemplate(productionTemplate)}
                />
                &nbsp;
                <EditButton
                  className="float-end"
                  onClick={() => setProductionTemplateInEditModal(productionTemplate)}
                />
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <CreateButton onClick={() => setCreateProductionTemplateModalActive(true)} />

      {createProductionTemplateModalActive &&
        <CreateModal
          onHide={() => setCreateProductionTemplateModalActive(false)}
          modalTitle="Productie template toevoegen"
          loading={createProductionTemplate.isLoading}
          formFields={formFields}
          includeData={{ tasks: [] }}
          onSave={productionTemplate => createProductionTemplate(
            productionTemplate,
            { callback: () => setCreateProductionTemplateModalActive(false) }
          )}
        />
      }

      {productionTemplateInEditModal &&
        <EditModal
          onHide={() => setProductionTemplateInEditModal(null)}
          modalTitle="Productie template aanpassen"
          loading={updateProductionTemplate.isLoading}
          initialState={productionTemplateInEditModal}
          formFields={formFields}
          onSave={productionTemplate => updateProductionTemplate(
            productionTemplate,
            { callback: () => setProductionTemplateInEditModal(null) }
          )}
        />
      }
    </Container>
  </>;
};
export default ProductionTemplatesList;
